import LoanTypeInitial from '../LoanTypeInitial';
import LoanType from '../LoanType';
import LoanAmount from '../LoanAmount';
import RenovationCost from '../RenovationCost';
import HomeValue from '../HomeValue';
import HomeValueEstimation from '../HomeValueEstimation';
import PurchaseStage from '../PurchaseStage';
import Downpayment from '../Downpayment';
import ProjectSize from '../ProjectSize';
import AfterRenovationValue from '../AfterRenovationValue';
import RemainingBalance from '../RemainingBalance';
import CreditScore from '../CreditScore';
import Zip from '../Zip';
import Address from '../Address';
import Hawaii from '../Zip/Hawaii';
import Name from '../Name';
import Email from '../Email';
import Phone from '../Phone';
import HomeType, {HomeTypeMessage, HomeTypeMultiUnit} from '../HomeType';
import PropertyUse, {PropertyUseMessage} from '../PropertyUse';
import Bankruptcy, {BankruptcyMessage} from '../Bankruptcy';
import RenovationStart, {
  RenovationStartAfterClosing,
  PurchaseClosing,
} from '../RenovationStart';
import CombinedIncome from '../CombinedIncome';
import Debt from '../Debt';
import Dti from '../Dti';
import WhoPerforming from '../Contractors/WhoPerforming';
import ContractorSecured from '../Contractors/ContractorSecured';
import Subcontractors from '../Contractors/Subcontractors';
import ContractorLicensed from '../Contractors/ContractorLicensed';
import ContractorLicensedConfirmation from '../Contractors/ContractorLicensedConfirmaton';
import Blueprints from '../Contractors/Blueprints';
import MortgageRate from '../MortgageRate';
import Intro from '../Intro';
import {GcConsent, GcIntro} from '../GcIntro';
import SelfReportedOrigin from '../SelfReportedOrigin';
import Land from '../Land';
import Adu from '../Adu';
import PurchasePrice from '../PurchasePrice';
import CompleteTearDown from '../Structures/CompleteTearDown';
import VAStatus from '../VAStatus';
import Redirection from '../Redirection';
import RenofiContractor from '../Contractors/RenofiContractor';

const routes = [
  {path: '/intro', component: Intro, progress: 0},
  {path: '/redirecting/:id', component: Redirection, progress: 0},
  {path: '/gc-intro', component: GcIntro, progress: 1},
  {path: '/gc-consent', component: GcConsent, progress: 1},
  {path: '/personal-info', component: Name, progress: 1},
  {
    path: '/loan-type-initial',
    component: LoanTypeInitial,
    progress: 0,
    name: 'Loan-Type-Initial',
  },
  {path: '/loan-type', component: LoanType, progress: 1, name: 'Loan-Type'},
  {path: '/land', component: Land, progress: 1},
  {
    path: '/construction-purchase/home-value',
    component: HomeValue,
    progress: 1.5,
  },
  {
    path: '/construction/mortgage-balance',
    component: RemainingBalance,
    progress: 2,
  },
  {path: '/adu', component: Adu, progress: 2},
  {path: '/loan-amount', component: LoanAmount, progress: 2},
  {
    path: '/construction/structure/tear-down',
    component: CompleteTearDown,
    progress: 2.5,
  },
  {path: '/renovation-cost', component: RenovationCost, progress: 3},
  {path: '/home-value', component: HomeValue, progress: 4},
  {path: '/address', component: Address, progress: 4},
  {
    path: '/owned/loan-amount-for-build',
    component: LoanAmount,
    progress: 4,
  },
  {path: '/home-value-estimation', component: HomeValueEstimation, progress: 7},
  {path: '/purchase-stage', component: PurchaseStage, progress: 2},
  {path: '/purchase/address', component: Address, progress: 2.5},
  {path: '/purchase-price', component: PurchasePrice, progress: 3},
  {path: '/downpayment', component: Downpayment, progress: 4},
  {path: '/project-size', component: ProjectSize, progress: 5},
  {
    path: '/construction-purchase/structure/tear-down',
    component: CompleteTearDown,
    progress: 5.5,
  },
  {path: '/purchase-renovation-cost', component: RenovationCost, progress: 6},
  {
    path: '/loan-amount-for-build',
    component: LoanAmount,
    progress: 6,
  },
  {
    path: '/after-renovation-value',
    component: AfterRenovationValue,
    progress: 6,
  },
  {path: '/land/purchase-stage', component: PurchaseStage, progress: 7},
  {path: '/land/downpayment', component: Downpayment, progress: 7.6},
  {path: '/mortgage-balance', component: RemainingBalance, progress: 7},
  {path: '/credit-score', component: CreditScore, progress: 8},
  {path: '/zip', component: Zip, progress: 9},
  {path: '/hawaii', component: Hawaii, progress: 9},
  {path: '/name', component: Name, progress: 10},
  {path: '/email', component: Email, progress: 11},
  {path: '/phone', component: Phone, progress: 12},
  {
    path: '/structure/tear-down',
    component: CompleteTearDown,
    progress: 12.3,
  },
  {path: '/renovation-type', component: HomeType, progress: 13},
  {
    path: '/renovation-type/multi-unit',
    component: HomeTypeMultiUnit,
    progress: 13.5,
  },
  {
    path: '/renovation-type/message',
    component: HomeTypeMessage,
    progress: 13.5,
  },
  {path: '/property-use', component: PropertyUse, progress: 14},
  {path: '/property-use/message', component: PropertyUseMessage, progress: 14},
  {path: '/bankruptcy', component: Bankruptcy, progress: 15},
  {path: '/bankruptcy/message', component: BankruptcyMessage, progress: 15},
  {
    path: '/renovation-start',
    component: RenovationStart,
    progress: 16,
    subProgress: 0,
  },
  {
    path: '/purchase-closing',
    component: PurchaseClosing,
    progress: 16,
    subProgress: 0,
  },
  {
    path: '/renovation-start/after-closing',
    component: RenovationStartAfterClosing,
    progress: 16.5,
    subProgress: 50,
  },
  {
    path: '/combined-income',
    component: CombinedIncome,
    progress: 17,
    subProgress: 0,
  },
  {
    path: '/payments',
    component: Debt,
    progress: 18,
    subProgress: 33,
    exact: true,
  },
  {
    path: '/dti',
    component: Dti,
    progress: 19,
    subProgress: 46,
    exact: true,
  },
  {
    path: '/phone-number',
    component: Phone,
    progress: 26,
    subProgress: 56,
  },
  {
    path: '/rate',
    component: MortgageRate,
    progress: 20,
    subProgress: 66,
  },
  {
    path: '/contractors',
    component: WhoPerforming,
    progress: 21,
    subProgress: 0,
  },
  {
    path: '/contractors/licensed',
    component: ContractorLicensed,
    progress: 22,
    subProgress: 25,
  },
  {
    path: '/contractors/licensed/confirmation',
    component: ContractorLicensedConfirmation,
    progress: 22,
    subProgress: 30,
  },
  {
    path: '/contractors/secured',
    component: ContractorSecured,
    progress: 22,
    subProgress: 20,
  },
  {
    path: '/contractors/find',
    component: RenofiContractor,
    progress: 22.5,
    subProgress: 35,
  },
  {
    path: '/contractors/subcontractors',
    component: Subcontractors,
    progress: 23,
    subProgress: 35,
  },
  {
    path: '/plans',
    component: Blueprints,
    progress: 24,
    subProgress: 50,
  },
  {
    path: '/va-status',
    component: VAStatus,
    progress: 25,
    subProgress: 65,
  },
  {
    path: '/phone-final',
    component: Phone,
    progress: 27,
    subProgress: 90,
  },
  {
    path: '/borrower-origin',
    component: SelfReportedOrigin,
    progress: 27,
  },
];

export default routes;
