import {useEffect} from 'react';

import {Flex} from 'rebass';
import {useReactiveVar} from '@apollo/client';
import PropTypes from 'prop-types';
import {isNull} from 'lodash';

import {sendEvent} from '@renofi/analytics';
import {webToCase, mediaQuery} from '@renofi/utils';

import {useNext} from '../../hooks';
import {Heading, Narrow, Small} from '../../components';
import {setLead, leadVar} from '../../api/cache';
import YesNoSelect from '../../components/YesNoSelect';

import CheckItem from './CheckItem';

function RenofiContractor({updateScenario}) {
  const next = useNext();
  const lead = useReactiveVar(leadVar);
  const {subcontractorsSecured, contractorRequestedAt} = lead;

  const value =
    Boolean(contractorRequestedAt) || isNull(contractorRequestedAt)
      ? Boolean(contractorRequestedAt)
      : undefined;

  useEffect(() => sendEvent('POS/Renofi-Contractor-Page'), []);

  function onSubmit(newValue) {
    sendEvent('POS/Renofi-Contractor-Entered', {
      renofiContractorRequested: newValue,
    });

    if (newValue && !contractorRequestedAt) {
      sendEvent('POS/Renofi-Contractor-Requested');
      webToCase(lead);

      const contractorRequestedAt = new Date().toISOString();
      setLead({contractorRequestedAt});
      updateScenario({contractorRequestedAt});
    } else if (
      !newValue &&
      (contractorRequestedAt || !isNull(contractorRequestedAt))
    ) {
      setLead({contractorRequestedAt: null});
      updateScenario({contractorRequestedAt: null});
    }

    next('/plans');
  }

  return (
    <>
      <Heading>
        {subcontractorsSecured
          ? 'Are you interested in receiving designer or general contractor recommendations from our network?'
          : 'Do you need help finding a designer or general contractor for your project?'}
      </Heading>

      <Narrow maxWidth={'100%'}>
        <Flex
          css={mediaQuery({
            gap: ['15px', 0],
          })}
          mt={['-8px', 0]}
          mb={[24, 36]}
          width="100%"
          justifyContent={['flex-start', 'center']}
          flexDirection={['column', 'row']}
          alignItems={['left', 'center']}>
          <CheckItem type="save">
            Save hours by accessing our network of trusted providers.
          </CheckItem>
          <CheckItem type="receive">
            Receive bids from vetted architects & contractors.
          </CheckItem>
          <CheckItem type="free">
            FREE service
            <br /> No commitment required
          </CheckItem>
        </Flex>
      </Narrow>
      <YesNoSelect value={value} onSubmit={onSubmit} />
      <Small mt={[24, 36]}>
        By answering “yes” you agree that we will share your contact information
        (name, email and phone number) with Cottage. Cottage is now part of
        RenoFi. Cottage will continue to be available in the San Francisco Bay
        Area, Greater Los Angeles, San Diego and Seattle and we look forward to
        serving new markets soon.
      </Small>
    </>
  );
}

RenofiContractor.propTypes = {
  updateScenario: PropTypes.func.isRequired,
};

export default RenofiContractor;
