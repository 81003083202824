import PropTypes from 'prop-types';
import {Flex, Text} from 'rebass';

import {darkBlue} from '@renofi/utils/src/colors';

import {ReactComponent as SaveIcon} from './img/save.svg';
import {ReactComponent as ReceiveIcon} from './img/receive.svg';
import {ReactComponent as FreeIcon} from './img/free.svg';

const CheckItem = ({type, children}) => {
  const renderIcon = () => {
    switch (type) {
      case 'save':
        return <SaveIcon />;
      case 'receive':
        return <ReceiveIcon />;
      case 'free':
        return <FreeIcon />;
      default:
        return null;
    }
  };
  return (
    <Flex flex={1} alignItems={'left'} mx={['8px', 16]}>
      {renderIcon()}
      <Text
        textAlign="left"
        lineHeight={['16px', '18px']}
        fontWeight="100"
        ml={'10px'}
        color={darkBlue}
        opacity={0.6}
        fontSize={[14, 16]}>
        {children}
      </Text>
    </Flex>
  );
};

CheckItem.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['save', 'receive', 'free']),
};

export default CheckItem;
