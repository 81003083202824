import React, {useEffect, useState} from 'react';

import {Box} from 'rebass';
import {Redirect, Route, Switch, useLocation} from 'react-router-dom';
import {animated, useTransition} from 'react-spring';
import {useReactiveVar} from '@apollo/client';

import {constants, useScreenSize, useUTMTags} from '@renofi/utils';
import {slow} from '@renofi/utils/src/animation';
import {stringifyUrlParams} from '@renofi/utils/src/format';
import {ProgressBar} from '@renofi/components';

import {RouteWrapper} from '../components';
import {progressVar, setProgress} from '../api/cache';
import useApi from '../api/useApi';
import {getIsGCPartnerSource, getIsSRLoSource} from '../util';

import routes from './routes';
import {Wrapper} from './styled';

const isTest = process.env.REACT_APP_ENV === 'test';

let prevPageIndex = -1;

function AnimatedRouter() {
  const {updateScenario, createScenario} = useApi();
  const [contentHeight, setContentHeight] = useState(0);
  const {isDesktop} = useScreenSize();
  const location = useLocation();
  const {progress} = useReactiveVar(progressVar);
  const {utmTags} = useUTMTags(constants.UTM_COOKIE_KEYS.renofi);
  const {utmTags: sloUtmTags} = useUTMTags(constants.UTM_COOKIE_KEYS.slo);
  const isGcPartner = getIsGCPartnerSource(utmTags);
  const isSrLo = getIsSRLoSource(sloUtmTags);

  const locationIndex = routes.findIndex(
    (route) => route.path === location.pathname,
  );

  const transitions = useTransition(location, {
    from: {
      transform: `scale(1) translate(${
        prevPageIndex <= locationIndex ? 600 : -600
      }px, 0px)`,
      opacity: 0,
    },
    enter: {
      transform: `scale(1) translate(0px, 0px)`,
      opacity: 1,
    },
    leave: {
      transform: `scale(${isDesktop ? '0.8' : '1'}) translate(${
        prevPageIndex <= locationIndex ? -500 : 500
      }px, ${isDesktop ? '120px' : '0px'})`,
      opacity: 0,
    },
    config: {...slow, duration: isTest ? 0 : 420},
    onRest: () => {
      prevPageIndex = locationIndex;
    },
  });

  useEffect(() => {
    setProgress(location.pathname);
  }, [location]);

  return (
    <>
      <ProgressBar
        progress={progress * (100 / 27)}
        height={2}
        bgColor="white"
      />
      <Wrapper>
        <Box height={contentHeight} />
        {transitions((styles, location) => (
          <animated.div
            style={{
              width: '100%',
              position: 'absolute',
              zIndex: '100',
              top: 0,
              ...styles,
            }}>
            <RouteWrapper
              onHeightChange={setContentHeight}
              dynamic={location.pathname === '/payments'}
              top={location.pathname === '/payments' ? 48 : null}>
              <Switch location={location}>
                {routes.map(({path, component, ...props}) => {
                  const Component = component;
                  return (
                    <Route
                      key={path}
                      path={path}
                      exact
                      render={() => {
                        return (
                          <Component
                            {...props}
                            updateScenario={updateScenario}
                            createScenario={createScenario}
                          />
                        );
                      }}
                    />
                  );
                })}
                <Redirect
                  to={
                    isGcPartner
                      ? `/gc-intro?${stringifyUrlParams()}`
                      : isSrLo
                        ? `/loan-type-initial?${stringifyUrlParams()}`
                        : `/loan-type?${stringifyUrlParams()}`
                  }
                />
              </Switch>
            </RouteWrapper>
          </animated.div>
        ))}
      </Wrapper>
    </>
  );
}

export default AnimatedRouter;
