import {useReactiveVar} from '@apollo/client';

import {useUpdateScenario} from '@renofi/api';
import {
  isLeadValidForCreation,
  savePersonalLeadData,
} from '@renofi/utils/src/lead';

import {leadVar} from '../api/cache';

import useCreateLead from './useCreateLead';

const useCreateFakeLead = () => {
  const lead = useReactiveVar(leadVar);
  const createLead = useCreateLead();
  const {updateScenario} = useUpdateScenario({id: lead?.id});

  const createFakeLead = async (onInvalid = () => ({})) => {
    if (!isLeadValidForCreation(lead, ['selfReportedCreditScore'])) {
      onInvalid();
      return;
    }

    const rsp = await createLead();
    const id = rsp?.lead?.id || lead?.id;

    savePersonalLeadData(lead, id);
    updateScenario({variables: {...lead, id}});
  };

  return createFakeLead;
};

export default useCreateFakeLead;
